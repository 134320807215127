body {
  margin: 10px 10px;
  padding: 0;
  font-family: sans-serif;
}
@media all {
  .page-break { display: none; }
}

@media print {
  .page-break {
    display: block;
    page-break-after: always;
  }
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  /* Overrde Bootstrap style so that URLs of a-tags don't print with the tag text */
  /* Thanks to https://processwire.com/talk/topic/9602-printing-page-in-browser-shows-urls-next-to-links */
  @media print {
    a[href]:after {
      content: none !important;
    }
  }
}

.login-button span {background-color: rgb(66,133,244); color: white; padding-left: 10px !important;}
.login-error {color: red;}
.login-disabled {opacity: 0.2;}
.login-fixed-height {height: 70px;}
.login-status-message-spacer {margin-bottom: 25px;}
.login-hidden {display: none;}

.arrow-icon {height: 16px; width: 16px;}
.arrow-icon:hover {background-color: #ccc;}

.header-title {padding-top: 6px; font-size: 1.3em;}
.header {background-color: #ADD8E6; padding: 15px 0; border-radius: 10px; margin-bottom: 20px;}
.header-top-adjust {padding-top: 8px;}

.form-top-adjust {padding-top: 10px;}

.button-separator {margin-right: 5px;}
.bulletin-announcement-separator {margin-bottom: 10px;}
.bulletin-item p {display:inline;}
.bulletin-header {font-weight: bold;}
.loading-separator {margin-top: 20px;}

.contacts-header {font-weight: bold;}
input.unshaded-input.form-control {background-color: whitesmoke;}
.is-invalid {border-color: red;}
.make-inline p {display: inline;}
.spinner-border {height:1.5rem; width:1.5rem;}
.upload-file-name {margin-top: 4px; margin-bottom: 0;}

.staff-directory-table {overflow: auto; max-height: 800px; font-size:0.80em;}
.staff-directory-table tr td {min-width: 70px; border-right:1px solid #ccc;}
.staff-pix {width:100px;}

.hidden {display:none;}
.active .document-link {color: blue;}

.import-field-data {font-size: 1em; margin-top:10px;}

.controls-disabled {opacity: 0.30;}
.item-textarea {height: 200px !important;}

.list-group {cursor: pointer; max-height: 700px; overflow-y: scroll;}
.item-list-body {font-size: 0.85em;}
.list-group-item.active {color: black;}

.pinwheel {height: 40px; width: 40px;}
.navbar-center-buttons {text-align: center;}
.navbar-separator {margin-bottom: 10px;}

.navbar-left-separator {margin-left: 10px;}
.navbar-top-separator {margin-top: 10px;}
.navbar-min-height {min-height: 34px;}

.list-grid-parent {width: 100%; margin-top: 10px;}
.list-top-separator {margin-top: 10px;}
.list-pointer {cursor: pointer;}

.master-detail-top-separator {margin-top: 10px;}
.course-student-list-top-separator {margin-top: 10px;}

.error {color: red;}

.student-detail-grid-parent {width: 100%; margin-top: 10px;}

.student-detail-top-separator {margin-top: 10px;}

.student-detail-column-header {background-color: #eee;}
.student-detail-suppressed {text-decoration: line-through}
.student-detail-required {background-color: #ddd;}
.student-detail-partial-credit {background-color: yellow;}
.student-detail-d-grade {background-color: #CDE6FF;}
.student-detail-subject-title {padding: 2px 15px !important; background-image: none !important; cursor: hand !important; color: #2e6e9e !important;}
td {padding: 8px;}

.login-control {cursor: pointer;}

.login-help {text-align: left; margin-top: 15px;}

.login-show-help-header {
  font-weight: bold;
}

.list-scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.at-risk-subject-button {

}

.requirement-top-separator {
  margin-top: 20px;
}

.at-risk-small-margin>h5, .at-risk-small-margin>h6 {
  margin: 2px;
}

.unbold {
  font-weight:normal;
}

.at-risk-list-user-name {
  font-style: italic;
  font-size: 0.8em;
}
.list-student-number {
  font-size: 0.8em;
}
.list-item-selected {
  background-color: #265988 !important;
  color: white;
}
/* We must force the height when using the <ReactList> component */
.list-paginator {
  height: 40px;
  padding-left: 0;
  padding-right: 0;
}

.help-hidden {
  display: none;
}

.help-icon {
  height: 20px;
}

.at-risk-add-profile {
  margin-right: 5px
}

.at-risk-checkbox {
  height: 17px;
  width: 17px;
  margin-top: 3px;
  margin: auto;
  cursor: not-allowed;
}

.at-risk-checkbox-editable {
  border: 1px solid #aaa;
  cursor: pointer;
}

.at-risk-link {
  cursor: pointer;
  color: #428bca;
  text-decoration: none;
}
.at-risk-link:hover {
  color: #2a6496;
  text-decoration: underline;
}

.at-risk-checkbox-checked {
  background-color: #428BCA;
}

.loading {
  height:30px;
  width:30px;
}

.sdusd-configuration-list {
  font-weight: bold;
}

.student-list-header {
  margin-bottom: 8px;
  font-weight: bold;
}
.grid-header {
  margin-top: 4px !important;
}
.grid-subheader {
  margin-top: 0 !important;
  margin-bottom: 2px;
}
.grid-font {
  font-size: 0.8em;
}
.grid-body-hide {
  display: none;
}
.grid-body-show {
  display: inherit;
}
.grid-ok {
  background-color: rgb(227,240,218);
}
.grid-not-ok, .grid-f-grade {
  background-color: rgb(255,0,0);
  color: white;
  /*background-color: rgb(239,223,222);*/
}
.grid-ip {
  background-color: rgb(220,220,220)
}
.grid-partial-credit {
  background-color: yellow;
}
.grid-table-borders th, .grid-table-borders td {
  border: 1px solid #ccc;
}
.grid-icon-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.sm-grid-icons {
  height:13px;
  width: 13px;
  cursor: pointer;
}
.grid-icons {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.grid-icons-spacer {
  margin-right: 15px;
}
.grid-icons:hover {
  background-color: #aaa;
}
.grid-table {
  margin-bottom: -10px !important;
}
.grid-subject-header {
  cursor: pointer;
}
.grid-sequence-satisfied-elsewhere {
  background-color: rgb(227,240,218);
  opacity: 0.60;
}
.grid-sequence-satisfied {
  background-color: rgb(227,240,218);
}
.grid-sequence-at-risk {
  background-color: rgb(255,0,0);
  color: white;
  /*background-color: rgb(239,223,222);*/
}
.grid-sequence-required {
  background-color: rgb(200,200,200);
}
.grid-sequence-inprogress {
  background-color: rgb(220,220,220);
}
.grid-d-grade {
  background-color: rgb(210,320,252)
}
.grid-gpa-atrisk {
  /*color: red;*/
  background-color: yellow;
  color: black !important;
}
.grid-suppressed {
  text-decoration: line-through;
  opacity: 0.7;
}
.course-list {
  font-size: 0.80em;
}
.course-list > tbody > tr > td {
  padding: 4px 2px !important;
  cursor: pointer;
}
.course-list-sort {
  cursor: pointer;
}
.course-list-cursor-pointer {
  cursor: pointer !important;
}
.course-list img {
  width: 16px;
  height: 16px;
}
.radio-padding {
  padding-left: 20px;
  font-weight: normal !important;
}
.course-list-header {
  font-weight: bold;
}
.course-list-selected {
  background-color: rgb(60,106,157);
  color: white;
}
.course-under-review {
  background-color: yellow !important;
  color: blue;
}
.course-list-selected.course-under-review {
  background-color: green;
  color: white;
}
.at-risk-deficient {
  background-color: rgb(239,223,222);
}
.summary-complete {
  background-color: rgb(227,240,218);
}
.summary-incomplete {
  background-color: rgb(239,223,222);
}
.summary-in-progress {
  background-color: rgb(255,242,204);
}
.summary-in-progress-bright {
  background-color: yellow;
}
.summary-complete-bright {
  background-color: green;
  color: white;
  font-weight: bold;
}
.summary-incomplete-bright {
  background-color: red;
  color: white;
  font-weight: bold;
}
.summary-subject {
  font-weight: bold;
}
.gpa-header {
  font-weight: bold;
}
.settings-divider {
  margin-top: 25px;
}
.click-table {
  cursor: pointer;
}
a {
  cursor: pointer;
}
.login-again {
  cursor: pointer;
  color: #0056b3;
}
.login-again:hover {
  color: #2a6496;
}
.under-review {
  background-color: yellow;
  color: black;
}
.weighted {
  color: blue;
}
.under-review-message {
  background-color: rgb(253,242,208);
}
.user-field-error label {
  color: red;
}
.user-field-error input {
  color: red;
  border: 1px solid red;
}
.at-risk-student-link {
  color: #0056b3 !important;
  cursor: pointer !important;
}
.at-risk-student-link:hover {
  color: #2a6496 !important;
}
.user-school-select {
  height: 150px !important;
}
.comments-box {
  height: 150px !important;
}
.ip-pl-courses-passed {
  font-style: italic;
}
/*select.form-control:not([size]):not([multiple]) {*/
  /*height: calc(3.25rem + 2px) !important;*/
/*}*/

.special-modal {
  position: absolute;
  top: 150px;
  right: 100px;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow-x: auto;
  overflow-y: auto;
}

.special-modal .modal-title {
  font-size: 1.2em;
  font-weight: bold;
}

.special-model .close {
  padding: 0 1rem !important;
}
.fg-not-passed {
  color: blue;
}

.student-number-list {
  height: 500px !important;
}

.ccte-complete {
  color: green;
}

.ccte-not-complete {
  color: red;
}

.ccte-pathway {
  font-weight: bold;
}

.ccte-table tbody {
  font-size: 0.85em;
}

.ccte-table tbody td {
  border: 1px solid #ddd;
}

.ccte-completed {
  background-color: rgb(227,240,218);
}

.ccte-attempted {
  background-color: rgb(253,242,208);
}

.ccte-not-completed {
  background-color: #ddd;
}

.ccte-completed-text {
  color: green;
}

.ccte-not-completed-text {

}

.gpa-separator {
  margin-bottom: 10px;
}

.grade-selected td {
  background-color: rgb(253,242,208) !important;
}

.capped {color: blue;}

.form-check-label {margin-bottom: 10px;}

.filter-warning {color: blue;}

.hide-component {display: none;}

.label-top-margin {margin-top: 5px;}
h6 {font-size:0.75em !important; font-style:italic;}
.footer {margin-top: 15px;}

.student-detail {width: 120px;}
.letter-filter {padding: 2px 2px; cursor: pointer;}
.letter-filter-active {background-color: red;}
.cursor-pointer {cursor: pointer;}
.on-lop {color: red;}
.off-lop {}
.off-lop-green {color: green;}
.student-details, .roster-details {font-size: 0.90em;}
.student-header {height: 75px;}
.date-on {display: table-cell; width: 20%;}
.date-off {display: table-cell; width: 20%;}
.reason {display: table-cell; width: 20%;}
.record-item {
  list-style-type: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  margin-bottom: 0;
  font-size: 1.1em;
}
.record-item li {display: table-cell;}
.top-spacer {margin-top: 20px;}
.header {font-weight: bold;}
.center {text-align: center;}
.right {text-align: right;}
.bold {font-weight: bold;}
.link {color: #0056b3; text-decoration: underline; cursor: pointer;}
.no-link {color: #bbb;}
.link-no-color {text-decoration: underline; cursor: pointer;}
.record-list-on-lop {background-color: rgb(244, 204, 205); border-bottom: 1px solid #aaa; padding-top: 10px;}
.record-list-off-lop {background-color: rgb(217, 234, 211); border-bottom: 1px solid #aaa; padding-top: 10px;}
.cursor-pointer {cursor: pointer;}
.schedule-top-margin {margin-top: 10px;}
.student-ids {height: 150px !important;}
.id-text-box {height: 400px !important;}
.push-footer {margin-bottom: 350px;}
.banner-on-lop {background-color: red; color: white; text-align: center; padding: 5px;}
.banner-off-lop {background-color: rgb(0,255,0); color: black; text-align: center; padding: 5px;}
.border-right {border-right: 1px solid #bbb;}
.term {font-weight: bold;}
.mission-textarea {height: 100px !important;}

.sitemap {width: 100%; height: 500px;}
